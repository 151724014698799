//
// Select2
//

// Base
@mixin select2-base() {
  $general-padding: 15px 15px;

  // Select2 base

  .select2-container--default {
    .select2-selection--single,
    .select2-selection--multiple {
      outline: none !important;
      @include kt-rounded {
        border-radius: $input-border-radius;
      }

      @include kt-not-rounded {
        border-radius: 0 !important;
      }

      height: auto;
      line-height: 0;
    }

    .select2-selection--single .select2-selection__arrow,
    .select2-selection--multiple .select2-selection__arrow {
      @include kt-arrow-icon(down);
      border: 0;
      top: 50%;
      position: absolute;
      margin-top: 0;
      margin-left: 0;
      font-size: 0.85rem;
      left: auto;
      right: 0;
      display: inline-block;
      width: 1.9rem;

      b {
        display: none;
      }
    }

    .select2-selection--single .select2-selection__rendered {
      position: relative;
      padding: $input-btn-padding-y 3rem $input-btn-padding-y
        $input-btn-padding-x;
      line-height: $input-btn-line-height;

      .select2-selection__clear {
        border: 0;
        position: absolute;
        top: 50%;
        @include kt-la-icon-self("\f110");
        font-size: 1.4rem;
        display: inline-block;
        left: auto;
        right: $input-btn-padding-x + 0.85rem;
        margin-right: 0.4rem;
        margin-top: -(2rem/2);
      }
    }

    .select2-selection--multiple .select2-selection__rendered {
      padding: ($input-btn-padding-y - 0.28rem) $input-btn-padding-x;
      line-height: $input-btn-line-height;

      .select2-selection__choice {
        padding: 0.05rem 0.4rem 0.05rem 0.4rem;
        font-size: 1rem;
        margin: 0.1rem 0.4rem 0.1rem 0;
        position: relative;
        float: left#{"/*rtl:right*/"};

        @include kt-not-rounded {
          border-radius: $input-border-radius !important;
        }

        .select2-selection__choice__remove {
          @include kt-la-icon-self("\f110");
          font-size: 1.4rem;
          display: inline-block;
          line-height: 0;
          margin-right: 0.3rem;
          position: relative;
          top: 0.1rem;
        }
      }

      .select2-search__field {
        font-weight: 300;
        margin: 0.25rem 0.25rem 0.25rem 0;
      }
    }

    .select2-search--dropdown .select2-search__field {
      outline: none !important;
      @include kt-rounded {
        border-radius: $input-border-radius;
      }
      @include kt-not-rounded {
        border-radius: 0 !important;
      }
    }

    .select2-search--dropdown {
      padding: $general-padding;
    }

    // results
    .select2-results__option {
      padding: 5px 15px;

      &[aria-disabled="true"] {
        cursor: not-allowed;
      }

      .select2-results__group {
        padding: 5px 15px;
        font-weight: 600;
      }

      .select2-results__option {
        padding: 5px 30px;
      }
    }
  }

  .select2-container .select2-search--inline .select2-search__field {
    margin: 0;
  }

  // Select2 component

  select.select2 {
    opacity: 0;
  }

  .select2 {
    > select.form-control {
      opacity: 0;
    }
  }

  // Select2 with bootstrap group input
  .input-group {
    > .select2-hidden-accessible {
      &:first-child + .select2-container {
        > .selection {
          .select2-selection--single {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &,
          &.form-control {
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }

      // the most right
      &:not(:first-child) + .select2-container:last-child {
        > .selection {
          .select2-selection--single {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          .select2-selection {
            &,
            &.form-control {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }

      &:first-child + .select2-container + .input-group-text,
      &:not(:first-child) + .select2-container + .input-group-text {
        border-left: 0;
      }

      &:not(:first-child) + .select2-container:not(:last-child) {
        > .selection {
          .select2-selection--single {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          .select2-selection {
            &,
            &.form-control {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
  }
}

// Skin
@mixin select2-skin() {
  // select2 base
  .select2-container--default {
    .select2-selection--multiple,
    .select2-selection--single {
      border: 1px solid $input-border-color;

      .select2-selection__placeholder {
        color: $input-placeholder-color;
      }
    }

    &.select2-container--focus,
    &.select2-container--open {
      .select2-selection--multiple,
      .select2-selection--single {
        border-color: $input-focus-border-color;
      }
    }

    .select2-selection--single .select2-selection__rendered {
      color: $input-plaintext-color;

      .select2-selection__clear {
        color: kt-base-color(label, 2);
      }
    }

    .select2-selection--multiple .select2-selection__rendered {
      color: $input-plaintext-color;

      .select2-selection__choice {
        color: kt-base-color(label, 2);
        background: kt-base-color(grey, 1);
        border: 1px solid kt-base-color(grey, 2);

        .select2-selection__choice__remove {
          color: kt-base-color(label, 2);
        }
      }

      .select2-search__field {
        @include kt-input-placeholder($input-placeholder-color);
      }
    }

    .select2-search--dropdown .select2-search__field {
      border: 1px solid $input-border-color;
    }

    //.select2-container--below.

    // dropdown
    .select2-dropdown {
      border: 1px solid $input-border-color;
      box-shadow: $kt-dropdown-shadow;
    }

    // result options
    .select2-results__option {
      &[aria-selected="true"] {
        background: kt-base-color(grey, 1);
        color: kt-base-color(label, 2);
      }

      &.select2-results__option--highlighted {
        background: kt-base-color(grey, 1);
        color: kt-base-color(label, 2);
      }
    }

    // disabled mode
    &.select2-container--disabled {
      cursor: not-allowed;

      .select2-selection--multiple,
      .select2-selection--single {
        cursor: not-allowed;
        background: kt-base-color(grey, 1);
        border-color: kt-base-color(label, 2);
      }
    }
  }

  // select2 components

  .select2 {
    // pill style
    &.select2--air {
      .select2-container--default {
        .select2-selection--single,
        .select2-selection--multiple {
          box-shadow: $kt-dropdown-shadow;
        }
      }
    }

    // pill style
    &.select2--solid {
      .select2-container--default {
        .select2-selection--multiple,
        .select2-selection--single {
          background-color: kt-base-color(grey, 1);
          border-color: kt-base-color(grey, 2);

          .select2-selection__placeholder {
            color: $input-placeholder-color;
          }
        }
      }
    }
  }
}

// Component Validation State
@mixin select2-validation-state($state, $color) {
  .is-#{$state} {
    .select2-container--default {
      .select2-selection--multiple,
      .select2-selection--single {
        border-color: $color;
      }

      &.select2-container--focus,
      &.select2-container--open {
        .select2-selection--multiple,
        .select2-selection--single {
          border-color: $color;
        }
      }
    }
  }
}

// Build
// Base
@include select2-base();

// Skin
@include select2-skin();

// Validation States
@include select2-validation-state(valid, kt-state-color(success, base));
@include select2-validation-state(invalid, kt-state-color(danger, base));
