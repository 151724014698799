//
// Header Menu
//

// Desktop Mode
@include kt-menu-hor-build-layout(kt-get($kt-header-config, menu, desktop));
@include kt-menu-hor-build-skin(
  kt-get($kt-header-config, menu, desktop),
  default
);

// Tablet & Mobile Mode
@include kt-menu-ver-build-layout(
  kt-get($kt-header-config, menu, tablet-and-mobile)
);
@include kt-menu-ver-build-skin(
  kt-get($kt-header-config, menu, tablet-and-mobile),
  default
);

// Header Menu Mobile Offcanvas
@include kt-offcanvas-build(
  kt-header-menu-wrapper,
  tablet-and-mobile,
  kt-get($kt-header-config, menu, offcanvas-mobile)
);

// Desktop mode
@include kt-desktop {
  // Header menu
  .kt-header-menu {
    // Menu nav
    .kt-menu__nav {
      // Menu item
      > .kt-menu__item {
        &:first-child {
          padding-left: 0;
        }

        // Menu link
        > .kt-menu__link {
          padding: 0.7rem 1.1rem;

          @include kt-rounded {
            border-radius: $kt-border-radius;
          }

          .kt-menu__link-text {
            color: #6c7293;
            font-weight: 500;
          }

          .kt-menu__hor-arrow {
            color: #c8cde8;
          }

          .kt-menu__hor-icon {
            color: #c8cde8;
          }
        }

        // Menu link states
        &:hover,
        &.kt-menu__item--hover,
        &.kt-menu__item--here,
        &.kt-menu__item--active {
          > .kt-menu__link {
            background-color: rgba(kt-brand-color(), 0.05);

            .kt-menu__link-text {
              color: kt-brand-color();
            }

            .kt-menu__hor-arrow {
              color: kt-brand-color();
            }

            .kt-menu__hor-icon {
              color: kt-brand-color();
            }
          }
        }

        // Menu submenu
        > .kt-menu__submenu {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }
}

// Media range(above 1024px and below 1200px)
@include kt-tablet-and-mobile() {
  .kt-header-menu-wrapper {
    .kt-header-menu {
      .kt-menu__nav {
        > .kt-menu__item {
          padding: 0px 3px;

          > .kt-menu__link {
            .kt-menu__link-text {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
