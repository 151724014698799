//
// BlockUI
//

// Base
.blockui {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  display: table;
  table-layout: fixed;

  &.blockui-noshadow {
    box-shadow: none;
  }

  @include kt-rounded {
    border-radius: $kt-border-radius;
  }

  > span {
    color: kt-base-color(label, 2);
    display: table-cell;
    vertical-align: middle;
    padding: 0.75rem 1.2rem;
    font-size: 1rem;
    font-weight: 400;

    > .kt-loader,
    > .kt-spinner {
      margin-right: 1.25rem;
    }
  }
}
