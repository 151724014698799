//
// Header Mobile
//

// Desktop mode
@include kt-desktop {
  .kt-header-mobile {
    display: none;
  }
}

// Tablet & mobile mode
@include kt-tablet-and-mobile {
  .kt-header-mobile {
    display: flex;
    justify-content: space-between;
    padding: 0 kt-get($kt-page-padding, mobile);
    height: kt-get($kt-header-config, base, mobile, self, default, height);
    min-height: kt-get($kt-header-config, base, mobile, self, default, height);
    position: relative;
    z-index: 1;
    background-color: #1a1a27;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);

    .kt-header__topbar--mobile-on & {
      box-shadow: none;
    }

    // Fixed
    .kt-header-mobile--fixed & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex);
    }

    // Logo
    .kt-header-mobile__logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    // Toolbar
    .kt-header-mobile__toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include kt-burger-icon-build(
        kt-header-mobile__toolbar-toggler,
        kt-get($kt-header-config, base, mobile, toolbar, toggler)
      );
      @include kt-burger-icon-skin(
        kt-header-mobile__toolbar-toggler,
        kt-get($kt-header-config, base, mobile, toolbar, toggler, color)
      );

      .kt-header-mobile__toolbar-toggler {
        margin: 0 0 0 1.5rem;
      }

      .kt-header-mobile__toolbar-topbar-toggler {
        @include kt-button-reset();
        display: flex;
        align-items: center;
        margin: 0 0.5rem 0 1.25rem;

        i {
          @include kt-transition();
          color: kt-get(
            $kt-header-config,
            base,
            mobile,
            toolbar,
            toggler,
            color,
            default
          );
          font-size: 1.5rem;
          line-height: 0;
        }

        &.kt-header-mobile__toolbar-topbar-toggler--active,
        &:hover {
          i {
            @include kt-transition();
            color: kt-get(
              $kt-header-config,
              base,
              mobile,
              toolbar,
              toggler,
              color,
              hover
            );
          }
        }
      }
    }
  }
}
